<template>
  <div>
    <treatment-history-detail
      :backRoute="backRoute"
      patientDetailRoute="DoctorPatientDetail"
      treatmentHistoryDetailRoute="DoctorPatientTreatmentDetail"
    />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails";
export default {
  name: "DoctorPatientTreatmentDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {
      backRoute: "DoctorPatientDetail"
    };
  },
  created() {
    if (this.$route.query.backRoute) {
      this.backRoute = this.$route.query.backRoute;
    }
  }
};
</script>